.react-select-theme div[class*="placeholder"] { 
    color: #000;
    margin-left: 10px;
}

.react-select-theme div[class*="control"] {
    border: none;
    box-shadow: none;
}

.react-select-theme div[class*="IndicatorsContainer"] { 
    background-color: #dcdcdc;
    padding: 2px 4px;
}

.react-select-theme div[class*="IndicatorsContainer"] span {
    display: none !important;    
}

.react-select-theme svg {
    transform: scale(1.3);
    color: #848484;
}

.react-select-theme div[class*="menu"] {
    margin: 2px;
    border-radius: none;
}