
#hamburguer {
  width: 40px;
  height: 100%;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#hamburguer span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: #1e194d;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#hamburguer span:nth-child(1) {
  top: 0px;
}

#hamburguer span:nth-child(2),#hamburguer span:nth-child(3) {
  top: 10px;
}

#hamburguer span:nth-child(4) {
  top: 20px;
}

#hamburguer.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#hamburguer.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburguer.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#hamburguer.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

