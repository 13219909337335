.rc-tooltip-content {
  border-radius: 5px;
}

.rc-tooltip-inner {
  background: #fff;
  border-radius: 5px;
  color: #1b1247;
  width: 515px;
  font-size: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

@media (max-width: 768px) {
  .rc-tooltip-inner {
    background: #fff;
    border-radius: 5px;
    color: #1b1247;
    font-size: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    max-width: 90%;
    margin-left: 5%;
    width: auto;
  }

  .rc-tooltip-placement-top .rc-tooltip-arrow {
    left: 83%;
  }
  .rc-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
    line-height: 1.5;
    font-size: 12px;
    padding: 1px;
    opacity: 0.9;
    background-color: transparent;
  }
}
